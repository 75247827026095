import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import logo from '../src/images/logo.png'
import vesu from '../src/images/весы_icon.png'
import book from '../src/images/book_icon.png'
import man from '../src/images/man.png'
import Slider from "react-slick";
import loco from '../src/images/location_icon.png'
import {Map, GoogleApiWrapper} from 'google-maps-react'
import { Link } from "react-scroll";
import { useState } from "react";
function App() {

  const [navbar, setNavbar] = useState(false);
 
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className=' overscroll-none'>
      <div id="home" className="  bg-first bg-clip-border bg-cover h-screen w-auto overscroll-x-none">

      <nav className="w-full bg-gray-800 shadow fixed overscroll-x-none">
            <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
                <div>
                    <div className="flex items-center justify-between py-3 md:py-5 md:block">
                        <a href="javascript:void(0)">
                            <h2 className="text-2xl font-bold text-white">Gambit Law</h2>
                        </a>
                        <div className="md:hidden">
                            <button
                                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {navbar ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                        viewBox="0 0 20 20"
                                        fill="white"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="white"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                            navbar ? "block" : "hidden"
                        }`}
                    >
                        <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                            <li className="text-white hover:text-blue-600">
                                <Link to="home"  smooth={true} offset={50} duration={500} >Home</Link>
                            </li>
                            <li className="text-white hover:text-blue-600">
                                <Link to="services"  smooth={true} offset={50} duration={500} >Services</Link>
                            </li>
                            <li className="text-white hover:text-blue-600">
                                <Link to="about"  smooth={true} offset={50} duration={500} >About US</Link>
                            </li>
                            <li className="text-white hover:text-blue-600">
                                <Link to="workers"  smooth={true} offset={50} duration={500} >Our team</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
      

        <div className=" pt-20">
          <img className=' mx-auto w-36 h-36 ' src={logo} />
          <div className='  pb-40 '>
            <h1 className='  text-4xl text-center'>Добро пожаловать в
              адвокадскую фирму </h1>
            <h1 className=' text-4xl text-center'>GAMBIT LAW</h1>


            <p className=' pt-6 text-2xl mx-auto md:w-1/2 text-center text-white'>Gambit law – юридическая помощь по всем отраслям права, непрерывная
              судебная практика, индивидуальный подход, ответственное отношение к
              работе. Для нас все дела важны, требуют максимум усилий и
              профессионализма!</p>
          </div>
        </div>
      </div>

      {/* 2 */}
      <div className=' bg-second bg-cover h-1/3 grid grid-cols-1 md:grid-cols-2 justify-items-center py-14'>
        <div className="w-56 h-56">
          <img className=' w-56 h-56 ' src={logo} />
        </div>

        <div className=" w-full md:mr-28">
          <h4 className=" text-2xl text-center md:text-left">АДВОКАТСКАЯ ФИРМА</h4>
          <h1 className=" text-3xl text-center md:text-left text-red-800">GAMBIT LAW</h1>
          <p className=" text-xl">Адвокатская фирма "Gambit Law" оказывает юридическую помощь во всех
            отраслях юридической профессии с 2021 года как самостоятельное адвокатское
            формирование. Хотя в настоящее время офис Адвокатской фирмы расположен
            только в городе Ташкенте, команда адвокатской фирмы чрезвычайно мобильна и
            оказывает юридическую помощь по всей территории Республики Узбекистан.</p>
        </div>
      </div>
      {/* 4 */}
      <Fouth />

      <div id="workers" className='bg-fouth bg-cover '>
        <h1 className='text-center text-3xl pt-14 text-white'>НАША КОМАНДА</h1>

        <Slider {...settings} >



          

          <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-20">
            <div className="flex justify-center  -mt-16">
              <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" />
            </div>
            <div>
              <h2 className="text-gray-800 text-3xl font-semibold text-center">Design Tools</h2>
              <p className="mt-2 text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!</p>
            </div>
            <div className="flex justify-end mt-4">
              <a href="#" className="text-xl font-medium text-indigo-500">John Doe</a>
            </div>
          </div>

          <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-20">
            <div className="flex justify-center  -mt-16">
              <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" />
            </div>
            <div>
              <h2 className="text-gray-800 text-3xl font-semibold text-center">Design Tools</h2>
              <p className="mt-2 text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!</p>
            </div>
            <div className="flex justify-end mt-4">
              <a href="#" className="text-xl font-medium text-indigo-500">John Doe</a>
            </div>
          </div>

          <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-20">
            <div className="flex justify-center  -mt-16">
              <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" />
            </div>
            <div>
              <h2 className="text-gray-800 text-3xl font-semibold text-center">Design Tools</h2>
              <p className="mt-2 text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!</p>
            </div>
            <div className="flex justify-end mt-4">
              <a href="#" className="text-xl font-medium text-indigo-500">John Doe</a>
            </div>
          </div>

          <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-20">
            <div className="flex justify-center  -mt-16">
              <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" />
            </div>
            <div>
              <h2 className="text-gray-800 text-3xl font-semibold text-center">Design Tools</h2>
              <p className="mt-2 text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!</p>
            </div>
            <div className="flex justify-end mt-4">
              <a href="#" className="text-xl font-medium text-indigo-500">John Doe</a>
            </div>
          </div>

          <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-20">
            <div className="flex justify-center  -mt-16">
              <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" src="https://images.unsplash.com/photo-1499714608240-22fc6ad53fb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80" />
            </div>
            <div>
              <h2 className="text-gray-800 text-3xl font-semibold text-center">Design Tools</h2>
              <p className="mt-2 text-gray-600">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!</p>
            </div>
            <div className="flex justify-end mt-4">
              <a href="#" className="text-xl font-medium text-indigo-500">John Doe</a>
            </div>
          </div>

        </Slider>
      </div>
        {/* map */}

        <div>

        </div>

      {/* 5 */}

      <div id="services" className=" bg-fifth bg-cover h-screen w-auto flex justify-center md:justify-end">
        <div className=" flex justify-end w-1/2 h-1/2">
          <ul className=" list-disc mt-6 md:mt-32 text-xs md:text-base">
            <li>ЮРИДИЧЕСКОЕ ОБСЛУЖИВАНИЕ ДЕЯТЕЛЬНОСТИ КОМПАНИЙ</li>
            <li>БАНКОВСКОЕ ПРАВО</li>
            <li>ФИНАНСОВОЕ ПРАВО</li>
            <li>СТРАХОВОЕ ПРАВО</li>
            <li>СЕМЕЙНОЕ ПРАВО</li>
            <li>ИНВЕСТИЦИОННОЕ ПРАВО</li>
            <li>НАЛОГОВОЕ И ТАМОЖЕННОЕ ПРАВО</li>
            <li className=" md:w-1/2">ЮРИДИЧЕСКАЯ ПОМОЩЬ В ЭНЕРГЕТИЧЕСКИХ И ДОБЫВАЮЩИХ
              ОТРАСЛЯХ ПРОМЫШЛЕННОСТИ И ОХРАНЫ ОКРУЖАЮЩЕЙ СРЕДЫ</li>
            <li className=" md:w-1/2">СУДЕБНЫЕ СПОРЫ / ДОСУДЕБНОЕ УРЕГУЛИРОВАНИЕ
              (ГРАЖДАНСКИЕ, АДМИНИСТРАТИВНЫЕ И ЭКОНОМИЧЕСКИЕ ДЕЛА)</li>
            <li>ЧАСТНАЯ СОБСТВЕННОСТЬ, НЕДВИЖИМОСТЬ И ИНЫЕ
              ИМУЩЕСТВЕННЫЕ ПРАВА</li>
            <li>АНТИМОНОПОЛЬНОЕ ПРАВО И ЗАКОНОДАТЕЛЬСТВО О
              КОНКУРЕНЦИИ</li>
            <li>КОРПОРАТИВНОЕ ПРАВО</li>
            <li>УГОЛОВНОЕ ПРАВО</li>
            <li>ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ И ПРАВА НА
              ТЕХНОЛОГИИ</li>
            <li>ТРУДОВОЕ ПРАВО</li>
            <li>ЮРИДИЧЕСКАЯ ПОМОЩЬ В ОБЛАСТИ ЗАЩИТЫ
              ПРАВ ПОТРЕБИТЕЛЕЙ</li>
            <li>СЛИЯНИЕ И ПОГЛОЩЕНИЕ</li>
          </ul>
        </div>
      </div>

      {/* map */}
      <div id="location">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d95852.93219047699!2d69.07554686249999!3d41.343568900000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8d64881506f7%3A0x7f6bdba26955534d!2sGambit%20Law!5e0!3m2!1sen!2s!4v1660037967058!5m2!1sen!2s" className=" w-full h-80" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>

      {/* Footer */}

      <footer className="footer p-10 bg-neutral-800 text-neutral-contents">
        <div className=" flex justify-start md:flex-wrap">
        <div>
          <div className="flex gap-2">

            <svg xmlns="http://www.w3.org/2000/svg" className=" h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <p className=" text-white">г.Ташкент, Алмазарский р-н, ул.Фароби</p>
          </div>

          <div className="flex gap-2">

            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <p className=" text-white">m_murod777@gmail.com</p>
          </div>

          <div className=" flex gap-2">

            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            <div>
              <p className=" text-white">+998 71 277 77 77</p>
              <p className=" text-white">+998 97 777 21 15</p>
              <p className=" text-white">+998 95 177 73 00</p>
            </div>


          </div>

        </div>

        <div className=" md:ml-96">
          <span className="footer-title text-white">Social</span>
          <div className="grid grid-flow-col gap-3">
            <a><svg xmlns="http://www.w3.org/2000/svg" className="fill-current w-6 h-6 text-white"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg></a>
            <a><svg xmlns="http://www.w3.org/2000/svg" className="fill-current w-6 h-6 text-white"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a>
            <a><svg xmlns="http://www.w3.org/2000/svg" className="fill-current w-6 h-6 text-white"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
          </div>
        </div>
        </div>
        <div className=" border-t border-white">
          <p className=" text-white text-center">© 2008-2021. Адвокатская фирма CODEX</p>
        </div>
         
      </footer>

    </div>

  );
}

export default App;





function Fouth() {
  return (
    <div id="about" className='bg-gray-100 bg-cover py-10 w-auto h-auto'>
      <h1 className=' text-center pt-5 text-2xl'>Примущества иметь дело с GAMBIT LAW</h1>

      <div className=' md:grid-cols-3 grid grid-cols-1 mt-14'>

        {/*  */}
        <div className="max-w-sm overflow-hidden rounded-xl duration-200 hover:scale-105 hover:shadow-xl mx-auto">
          <img className=' w-24 h-24 mx-auto' src={vesu} alt="plant" />
          <div className="p-5">
            <p className="text-medium mb-5 text-gray-700 text-center">На любой правой вопрос должно быть найдено
              правовое решение, то есть мы применяем только
              право и не используем иные способы для
              достижения цели</p>
          </div>
        </div>

        <div className="max-w-sm overflow-hidden rounded-xl duration-200 hover:scale-105 hover:shadow-xl mx-auto">
          <img className=' mx-auto w-24 h-24' src={book} />
          <div className="p-5">
            <p className="text-medium mb-5 text-gray-700 text-center">Объясняем сложные вещи простым языком</p>
          </div>
        </div>

        <div className="max-w-sm overflow-hidden rounded-xl duration-200 hover:scale-105 hover:shadow-xl mx-auto">
          <img className=' mx-auto w-24 h-24' src={man} />
          <div className="p-5">
            <p className="text-medium mb-5 text-gray-700 text-center">Индивидуально подходим к каждому проекту и
              предлагаем новые правовые позиции с учётом
              актуальных идей и тенденций</p>
          </div>
        </div>

      </div>
    </div>
  )
}
